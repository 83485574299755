import styled, { createGlobalStyle } from "styled-components";

const supportDeviceSize = 1080;
export const mobile = `max-width: ${supportDeviceSize}px`;

export const GlobalStyle = createGlobalStyle`
html {
font-size: 62.5%; // 1rem = 10px 로 변경 한 것
// 참고링크 = https://stackoverflow.com/questions/68790660/setting-root-font-size-not-affecting-rem-units-in-safari-for-margin-padding-et
// 128px => 12.8rem , 4px => 0.4rem 가능!
}

body {
    width: 100%;
    max-width: 120rem;
    min-height: 100dvh;
    margin: 0 auto;
    padding: 0;
    font-family: source-han-sans-korean, -apple-system, sans-serif, Roboto;
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #232323;
    overflow: scroll;

    .abeezee-regular {
      font-family: "ABeeZee", sans-serif;
      font-weight: 400;
      font-style: normal;
    }

    .abeezee-regular-italic {
      font-family: "ABeeZee", sans-serif;
      font-weight: 400;
      font-style: italic;
    }
}

* {
    font-family: source-han-sans-korean, -apple-system, sans-serif, Roboto;
    margin: 0;
    padding: 0;
    border: none;
    line-height: 1.5;
    letter-spacing: -0.02em;
    outline: none;
    box-sizing: border-box;
}
`;

export const MainStyled = styled.main`
  width: 100%;
  width: 120rem;
  min-width: 120rem;
  max-width: 120rem;
  height: 68.4rem;
  margin: 0 auto;
  display: flex;

  & > div {
    height: 100%;
    display: flex;
    flex-direction: column;
  }

  & > div:first-of-type {
    width: 66.2rem;
    padding: 10.08rem 0 0 9.6rem;
    background-color: #f2f3fd;

    .logo {
      width: 4rem;
      height: 4.14rem;
      margin-bottom: 3rem;
    }

    .sub-title {
      font-size: 1.6rem;
      font-weight: 500;
      color: #373737;
      margin-bottom: 0.4rem;
    }

    .main-title {
      font-size: 4rem;
      font-weight: 700;
      color: #2f2f2f;
      margin-bottom: 8rem;
    }

    .info {
      margin-bottom: 15rem;

      .info-row {
        display: flex;
        color: #f6f6f6;
        margin-bottom: 1.6rem;

        .label {
          padding: 0.6rem 1rem;
          height: max-content;
          font-size: 1.4rem;
          font-weight: 700;
          background-color: rgba(0, 0, 0, 0.3);
          border-radius: 0.6rem;
          backdrop-filter: blur(0.2rem);
          margin-right: 1.8rem;
        }

        .value {
          font-size: 1.6rem;
          line-height: 1.8;
          font-weight: 500;
          color: #373737;
        }
      }
    }
  }

  & > div:nth-of-type(2) {
    width: 53.8rem;
    justify-content: center;
    align-items: center;

    .container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 100%;
      max-width: 26.4rem;

      .title {
        margin-bottom: 3.2rem;
        font-size: 2rem;
        font-weight: 700;
        color: #232323;
      }

      &:has(.description) {
        .title {
          margin-bottom: 1.6rem;
        }
      }

      .description {
        font-size: 1.3rem;
        font-weight: 500;
        color: #4f4f4f;
        text-align: center;
        margin-bottom: 3.2rem;
      }

      .input-container {
        width: 100%;
        margin-bottom: 2.8rem;

        & > fieldset {
          width: 100%;
          height: max-content;

          &:first-of-type {
            margin-bottom: 1rem;

            & > label {
              &:has(input:focus),
              &.filled {
                & > span {
                  max-width: 2.5rem;

                  &.grow {
                    max-width: max-content;
                  }
                }
              }
            }
          }

          &:last-of-type {
            & > label {
              &:has(input:focus),
              &.filled {
                & > span {
                  max-width: 4.2rem;

                  &.grow {
                    max-width: max-content;
                  }
                }
              }
            }
          }

          & > label {
            display: flex;
            width: 100%;
            height: 4rem;
            position: relative;
            border-radius: 0.4rem;
            border: 0.1rem solid #e0e0e0;
            overflow: hidden;
            transition: all 0.2s linear;

            &.invalid {
              border-color: #ed2041;
            }

            &:has(input:focus),
            &.filled {
              & > span {
                max-width: 2.5rem;
                overflow: hidden;
                top: 0.3rem;
                transform: translateY(0);
                font-size: 1rem;
                font-weight: 500;
                color: #c2c2c2;
                opacity: 1;
                z-index: 1;
              }

              input {
                height: 1.7rem;
                top: 1.7rem;
                transform: translateY(0);
              }
            }

            & > span {
              position: absolute;
              width: max-content;
              max-width: max-content;
              height: 1.8rem;
              color: #898989;
              top: 50%;
              left: 1.2rem;
              transform: translateY(-50%);
              font-size: 1.2rem;
              transition: all 0.2s linear;
            }

            input {
              background: none;
              width: 100%;
              height: 100%;
              position: absolute;
              top: 50%;
              padding: 0 1.2rem;
              transform: translateY(-50%);
              font-size: 1.2rem;
              font-weight: 500;
              color: #2e2e2e;

              &:focus {
                height: 1.7rem;
                top: 1.7rem;
                transform: translateY(0);
              }
            }
          }

          .warning {
            margin-top: 0.4rem;
            font-size: 1rem;
            color: #ed2041;
            font-weight: 500;
          }
        }
      }

      .button-container {
        width: 100%;
        display: flex;
        column-gap: 1rem;

        &.col {
          flex-direction: column;
          row-gap: 1rem;
        }

        & > button {
          width: 100%;
          font-size: 1.2rem;
          font-weight: 700;
          color: white;
          background-color: #010242;
          padding: 1.15rem 0;
          text-align: center;
          border-radius: 0.4rem;
          cursor: pointer;
          transition: all 0.2s linear;

          &:disabled {
            background-color: #e0e0e0;
          }
        }
      }
    }
  }

  .help {
    display: flex;
    align-items: center;

    & > img {
      margin-right: 0.6rem;
    }

    .text {
      font-size: 1.4rem;
      color: #8d8d8d;

      & > span {
        font-weight: 500;
      }
    }
  }

  @media all and (${mobile}) {
    min-width: unset;
    width: 100dvw;
    flex-direction: column;

    & > div:first-of-type {
      width: 100%;
      height: 32.3rem;
      padding-left: 4.2rem;
      padding-top: 4.2rem;
      padding-bottom: 4.314rem;

      .logo {
        margin-bottom: 1.699rem;
      }

      .sub-title {
        font-size: 1.4rem;
        margin-bottom: 0.227rem;
      }

      .main-title {
        font-size: 2.6rem;
        margin-bottom: 4rem;
      }

      .info {
        margin-bottom: 0;

        .info-row {
          .label {
            font-size: 1.2rem;
            padding: 0.6rem 1.35rem;
          }

          .value {
            font-size: 1.3rem;
            line-height: 1.5;
            font-weight: 700;
          }
        }
      }
    }

    & > div:nth-of-type(2) {
      width: 100%;
      height: max-content;
      padding-top: 2.95rem;
      margin-bottom: 8.45rem;
    }

    .help {
      flex-direction: row;
      justify-content: center;
      align-items: center;
      margin-bottom: 3.6rem;

      & > img {
        width: 1.6rem;
        height: 1.6rem;
      }

      .text {
        font-size: 1.2rem;
      }
    }
  }
`;

export const ContentHeadStyled = styled.div<{ admin?: boolean }>`
  background-color: white;
  width: 100%;
  min-height: 9.4rem;
  padding: 3.25rem 5rem;
  font-size: 2rem;
  font-weight: 700;
  color: #232323;
  padding-left: ${({ admin }) => (admin ? "3rem" : "5rem")};
`;

export const ContentsStyled = styled.div`
  @media all and (${mobile}) {
    width: 100%;
    height: calc(100dvh - 25rem);
  }
`;
